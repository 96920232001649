import React, { Fragment,useEffect } from 'react'
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import Count from './Count';
import AboutNavigation from './AboutSubNav';
import TeamSlider from './TeamSlider';
const AboutUs = () => {
  useEffect(() => {
    document.title = 'About Us | KumbhaaTech Solutions';
    return () => {
        document.title = 'leading software company - KumbhaaTech Solutions';
    };
}, []);
  return (
    <Fragment>
      <Header />
      <div class="custom-banner">
        <div class="custom-banner-content">
          <h1>Kumbhaatech Solutions: Elevating Your Digital Presence</h1>
          <p>At Kumbhaatech Solutions, we specialize in delivering top-notch website design and content writing services that cater to your unique business needs. Our expert team is dedicated to creating visually stunning websites and compelling content that drive engagement and growth. Discover the perfect blend of creativity and affordability with Kumbhaatech Solutions</p>
          <br />
          <Link to='' className='abt-btn'>Get Started Today</Link>
        </div>
      </div>
      <AboutNavigation />
      <section className='abt-count-section' id='number'>
        <Count />
      </section>
      <section className='abt-why-choose' id='why'>
        <div className="container ">
          <div className=" col-lg-12 ">
            <div className='abt-content'>
              <h2 className="colour-blue"> Why you choose us?</h2>
              <hr style={{ width: '200px', margin: 'auto', borderBottom: '5px solid red', opacity: "1", marginTop: '10px', marginBottom: '40px' }} />
              <p>In today's digital age, having a website is essential, but the cost of professional web design can be prohibitive. At KumbhaaTech Solutions, we specialize in providing top-notch website design and development services at a low cost. Our expert team ensures you receive exceptional value without breaking the bank. Partner with us to elevate your online presence affordably. Ready to get started?</p>
            </div>
          </div>
          <div class="abt-card-container">
            <div class="abt-card">
              <img src="assets/images/kt-exprience.png" alt="" />
              <h4>Expertise and Experience</h4>
              {/* <a target="_blank" href="https://icons8.com/icon/mAlex3OyZVHj/development-skill">Experience</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a> */}
              <p>Since our inception in 2015, we have consistently delivered high-quality services that exceed client expectations.</p>
            </div>
            <div class="abt-card">
              <img src="assets/images/kt-affortable.png" alt="" />
              <h4>Affordable Prices</h4>
              {/* <a target="_blank" href="https://icons8.com/icon/PPp5M6ZT3S8B/cheap-2">Cheap 2</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a> */}
              <p>We pride ourselves on offering premium services at cheap and affordable prices, ensuring great value for your investment.</p>
            </div>
            <div class="abt-card">
              <img src="assets/images/client-kt.png" alt="" />
              <h4>Client-Centric Approach</h4>
              {/* <a target="_blank" href="https://icons8.com/icon/43640/windows-client">Windows Client</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a> */}
              <p>We prioritize understanding your unique needs and goals to provide tailored solutions that drive success.</p>
            </div>
            <div class="abt-card">
              <img src="assets/images/kt-recod.png" alt="" />
              <h4>Proven Track Record</h4>
              {/* <a target="_blank" href="https://icons8.com/icon/43998/edit-property">Edit Property</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a> */}
              <p>Our satisfied clients attest to our ability to deliver results, whether enhancing their online presence or excelling academically.</p>
            </div>
          </div>



        </div>

      </section>
      <section class="about-section" id='company'>
        <div class="container">
          <div className='about-heading col-lg-9'>
            <h2>Welcome to KumbhaaTech Soluctions</h2>
          </div>
          <div class="about-container align-items-center">
            <div class="col-lg-5 about-img">
              <img src="assets/images/home.png" alt="About Image" />
            </div>
            <div class="col-lg-7 about-content">
             
              <p>Welcome to KumbhaaTech, your premier destination for comprehensive digital solutions. Founded in 2015, KumbhaaTech has emerged as a leading company in website design and development, software services, content writing, SOP writing for overseas education, and assignment writing services. Our commitment to delivering high-quality services at a low cost has set us apart in the industry, enabling us to build lasting relationships with our clients.</p>
              <p>At KumbhaaTech, we understand that in today's digital age, having a professional and well-designed website is no longer a luxury—it's a necessity. However, we also recognize that the cost of professional web design can be a significant barrier for many businesses. That’s why we offer affordable website design and development services that deliver exceptional value without breaking the bank. Our team of skilled designers and developers works closely with you to create websites that are not only visually appealing but also user-friendly and optimized for performance.</p>


              <button className='main-button'> Get Appoinment Now!</button>
            </div>
          </div>
        </div>
      </section>

      <section className='key-module-blue ' style={{ marginTop: '50px' }} id='value'>

        <div class=" container">
          <div className='features-courseProject'>
            <div class="row">
              <div class="col-lg-12">
                <h2 >
                  Our Values
                </h2>
              </div>

              <div class="key-modules-container">


                <div class="key-module">
                  <div className='about-key-image'>
                      <img src="assets/images/KT-mission.png" alt="Our Mission" />
                      </div>
                  <h3>Our Mission</h3>
                  {/* Illustration by <a href="https://icons8.com/illustrations/author/ZQDZn9ZZj5aQ">Violetta Barsuk</a> from <a href="https://icons8.com/illustrations">Ouch!</a> */}
                  <br />
                  <p>  Website design according to us is a spiritual process that lets us understand you, your work, and your need after which we contemplate the best template design over which we can build your website.
                    We have a single and unshakeable faith in our effort to produce the best for you</p>
                </div>
                
                <div class="key-module">
                <div className='about-key-image'>
                      <img src="assets/images/KT-privacy.png" alt="Privacy " />
                      </div>
                  <h3>Privacy </h3>
                  
                  <br />
                  {/* Illustration by <a href="https://icons8.com/illustrations/author/lZpGtGw5182N">Elisabet Guba</a> from <a href="https://icons8.com/illustrations">Ouch!</a> */}
                  <p>  Privacy is a top priority at kumbhatech, reflected in our robust data protection measures and adherence to strict privacy standards. We safeguard sensitive information to ensure trust, confidentiality, and peace of mind for our clients and partners.</p>
                </div>
                <div class="key-module">
                  {/*  <img src="assets/images/support-red.png" alt="" /> */}
                  <div className='about-key-image'>
                      <img src="assets/images/KT-commitements.png" alt="Our Commitment" />
                      </div>
                  <h3>Our Commitment</h3>
                  <br />
                  {/* Illustration by <a href="https://icons8.com/illustrations/author/eEbrZFlkyZbD">Anna A</a> from <a href="https://icons8.com/illustrations">Ouch!</a> */}
                  <p> At KumbhaaTech, we are dedicated to maintaining the highest standards of quality and excellence. We strive to build lasting relationships with our clients by providing outstanding service and continuous support. Our team works tirelessly to ensure every project is completed on time, within budget, and to your complete satisfaction.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >

      <section style={{ marginTop: '50px' }} id='team'>
        <div className="container ">
          <div className=" col-lg-12 ">
            <h2 className="colour-blue" style={{ textAlign: 'center' }}>Founders</h2>

          </div>
          <div class="benefit-cards">

            <div class="benefit-card benefit-card2">
              <div className='benefit-inside'>
                <div class="benefit-image">
                  <img src="assets/images/kumbha logo.png" alt="Benefit Icon 4" />
                </div>
                <div className="team-conetent">

                  <h5>Mr.Hariharan V</h5>
                  <p>CEO & Founder</p>


                  <Link href="#"><i className="fa fa-linkedin"></i></Link>

                </div>
              </div>
            </div>
            <div class="benefit-card benefit-card2">
              <div className='benefit-inside'>
                <div class="benefit-image">
                  <img src="assets/images/kumbha logo.png" alt="Benefit Icon 4" />
                </div>
                <div className="team-conetent">

                  <h5>Mrs. Iniya H</h5>
                  <p>Director & Founder</p>


                  <Link href="#"><i className="fa fa-linkedin"></i></Link>

                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <section style={{ marginTop: '50px' }}>
        <div className="container">
        <TeamSlider />
        </div>
        <br />

      </section>


      <Footer />
    </Fragment>
  )
}

export default AboutUs;
