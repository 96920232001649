import React, { useState, useRef, useEffect } from 'react';

const SoftwareChoosing = () => {
  const ChoosingData = [
    {
      heading: "Cost-Effective Solutions",
      description: "We offer competitive pricing plans tailored to meet the budgetary constraints of businesses, startups, and individuals, ensuring accessibility and affordability for all.",
    },
    {
      heading: "Customization and Flexibility",
      description: "Our team works closely with clients to understand their unique needs and requirements, delivering customized software solutions that align with their business objectives and growth strategies.",
    },
    {
      heading: "Quality Assurance",
      description: ": Despite offering low-cost solutions, we never compromise on quality. Our software services are built using industry best practices and quality assurance processes to ensure performance, security, and reliability.",
    },
    {
      heading: "Ongoing Support and Maintenance",
      description: "We provide comprehensive support and maintenance services to ensure the continued success and optimization of our clients' software solutions, offering peace of mind and long-term value.",
    }
  ];

  return (
    <div  className="col-lg-6">
    <div className="accordions-new is-first-expanded">
      {ChoosingData.map((item, index) => (
        <AccordionItem key={index} title={item.heading} content={item.description} />
      ))}
    </div>
    </div>
  );
};

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      if (isOpen) {
        contentRef.current.style.maxHeight = contentRef.current.scrollHeight + 'px';
      } else {
        contentRef.current.style.maxHeight = '0px';
      }
    }
  }, [isOpen]);

  return (
    <article className="accordion">
      <div className="accordion-head" onClick={toggleAccordion}>
        <span>{title}</span>
        <span className={`icon ${isOpen ? 'open' : ''}`}>
          <i className={`fa ${isOpen ? 'fa-chevron-down' : 'fa-chevron-right'}`}></i>
        </span>
      </div>
      <div
        ref={contentRef}
        className={`accordion-body ${isOpen ? 'open' : ''}`}
        style={{ maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0px' }}
      >
        <div className="content">
          <p>{content}</p>
        </div>
      </div>
    </article>
  );
};

export default SoftwareChoosing;
