import React from 'react';
import AccordionItem from './AccordionItem';

const Accordion= () => {
  return (
    <div className="col-lg-6">
      <div className="accordions is-first-expanded">
        <AccordionItem
          title="Budget-Friendly Solutions"
          content="We believe that every business deserves a professional online presence, regardless of their budget. We offer a range of cheap website development packages to suit your needs and budget, ensuring you get the most value for your investment."
        />
        <AccordionItem
          title="High-Quality Design"
          content="Don't let the low cost web design fool you. Our team of experienced designers creates visually appealing and user-friendly websites that effectively represent your brand and resonate with your target audience."
        />
        
         <AccordionItem
          title="Responsive Layouts"
          content="Seamless user experience on all devices."
        />
         <AccordionItem
          title="Focus on Functionality"
          content=" We understand that a website needs to be more than just visually appealing. We ensure your website is inexpensive yet functional, featuring user-friendly navigation, fast loading times, and mobile responsiveness to deliver a seamless user experience across all devices."
        />
         <AccordionItem
          title="SEO Optimization"
          content="We integrate affordable Search Engine Optimization (SEO) best practices into your website development, helping you rank higher in search engine results pages (SERPs) and attract organic traffic."
        />
        
         <AccordionItem
          title="Transparent Communication"
          content="We believe in clear and open communication throughout the entire process. We'll work closely with you to understand your vision, preferences, and budget, keeping you informed every step of the way."
        />
         <AccordionItem
          title="Ongoing Support"
          content="Our commitment to your success extends beyond the initial website launch. We offer ongoing support packages to ensure your website remains updated, secure, and functioning optimally."
        />
        
      </div>
    </div>
  );
};

export default Accordion;
