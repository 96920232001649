import React, { Fragment, useState, useRef ,useEffect} from 'react'
import Header from '../Header';
import Footer from '../Footer';
import SubNavigation from '../OurServices/SubNav.jsx';
import Slider from 'react-slick';
import AcademicChoosing from './AcademicChoosing.jsx';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../slider.css';
import { Link } from 'react-router-dom';
const AcademicWriting = () => {
    useEffect(() => {
        document.title = ' Comprehensive Academic Writing Services | KumbhaaTech Solutions';
        return () => {
            document.title = 'leading software company - KumbhaaTech Solutions';
        };
    }, []);
    const [activeTab, setActiveTab] = useState('Tab1');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const cards = [
        {
            image: "assets/images/improve-academic.webp",
            heading: "Improved Academic Performance",
            description: "Academic writing services help MBA students improve their academic performance by providing high-quality, well-researched, and professionally written assignments that meet academic standards and requirements.",

        },
        {
            image: "assets/images/Time-management.webp",
            heading: "Time Management",
            description: "Outsourcing writing assignments to academic writing services allows MBA students to better manage their time and priorities, focusing on other aspects of their academic and personal lives.",

        },
        {
            image: "assets/images/understanting.jpg",
            heading: "Enhanced Understanding ",
            description: "Well-written assignments provided by academic writing services help MBA students deepen their understanding of course material and concepts, facilitating learning and retention.",

        },
        {
            image: "assets/images/reduce-stress.webp",
            heading: "Reduced Stress and Pressure",
            description: "Academic writing services alleviate stress and pressure for MBA students by providing timely and reliable assistance with writing assignments, reducing the burden of academic workload and deadlines.",

        }
    ];

    const handlePrevious = () => {
        sliderRef.current.slickPrev();
    };

    const handleNext = () => {
        sliderRef.current.slickNext();
    };


    return (
        <Fragment>
            <Header />
            <div class="custom-banner">
                <div class="custom-banner-content">
                    <h1>Comprehensive Academic Writing Services at Affordable Prices</h1>
                    <p>Achieve academic excellence with Kumbhaatech Solutions. Our expert team provides high-quality academic writing services tailored to your needs, ensuring well-researched and professionally written papers. Get top-notch academic assistance without breaking the bank.</p>
                    <br />
                    <Link to='' className='abt-btn'>Get Academic Help Today!</Link>
                </div>
            </div>

            <section className='subnav'>
                <div className="container" >
                    <div className='row'>
                        <div className="col-lg-12" >

                            <SubNavigation />
                        </div>
                    </div>
                </div>
            </section>
            <section className="web-development-container">
                <div className="container">
                    <div className="row">
                        <div className="web-heading col-lg-9">
                            <h2>Unveiling the Importance of Academic Writing for MBA Students in Overseas Education: Uses, Benefits, and How KumbhaaTech Provides Affordable Solutions.</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="web-content">
                                <p>In the pursuit of higher education, particularly an MBA in overseas institutions, academic writing becomes a crucial aspect of success. Assignments, essays, dissertations, and research papers are not just academic requirements; they are a reflection of a student's understanding, analysis, and critical thinking skills. </p>
                                <p>At KumbhaaTech, we understand the significance of academic writing support for MBA students. That's why we provide cheap academic writing services tailored to meet the diverse needs of students pursuing their MBA in overseas education.</p>
                                <button className="btn btn-primary">Learn More</button>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="image-container">
                                <img src="assets/images/education.webp" alt="Academic Writing for MBA Students" loading='lazy' decoding='async' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="web-section" id='understanding'>
                <div class="container">

                    <div class="web-container align-items-center">
                        <div class="col-lg-5 web-img">
                            <img src="assets/images/academic-writing.png" alt="Academic writing" loading='lazy' decoding='async' />
                        </div>
                        <div class="col-lg-7 web-content">
                            <h2>Understanding Academic Writing for MBA Students.</h2>
                            <p>Academic writing for MBA students encompasses a range of tasks, including essays, case studies, reports, research papers, and dissertations, aimed at assessing their knowledge, skills, and abilities in various business-related subjects. </p>
                            <p>These assignments often require in-depth research, analysis, critical thinking, and effective communication of ideas.</p>
                            <button className='main-button'> Get Appoinment Now!</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="info-section" id="purpose">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">The Purpose of Academic Writing</h2>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Demonstrating Understanding</h3>
                                    <p>Academic writing allows MBA students to demonstrate their understanding of key concepts, theories, and principles in their field of study.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Developing Analytical Skills</h3>
                                    <p>Through research and analysis, academic writing helps MBA students develop critical thinking and analytical skills essential for success in their academic and professional careers.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Enhancing Communication Skills</h3>
                                    <p>Writing assignments enable MBA students to communicate their ideas, arguments, and findings effectively, honing their written communication skills.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Preparing for Professional Practice </h3>
                                    <p>Academic writing prepares MBA students for the demands of professional practice by equipping them with research, analysis, and communication skills applicable in real-world business settings.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">Uses of Academic Writing</h2>
                                    <p>Academic writing for MBA students serves various purposes, including:</p>
                                </div>
                            </div>
                            <div className="info-card-with-icon-inner">
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/Academic-icon.png" alt="Assessment" loading='lazy' decoding='async'/>
                                        </div>
                                        <h5>Assessment</h5>
                                        <p>Academic writing assignments are used to assess MBA students' knowledge, skills, and understanding of course material and concepts.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/Academic-icon.png" alt="Learning and Development" loading='lazy' decoding='async'/>
                                        </div>
                                        <h5>Learning and Development</h5>
                                        <p>Writing assignments facilitate learning and development by encouraging MBA students to engage critically with course material, conduct research, and articulate their ideas effectively.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/Academic-icon.png" alt="Research and Analysis" loading='lazy' decoding='async'/>
                                        </div>
                                        <h5>Research and Analysis</h5>
                                        <p>Academic writing tasks require MBA students to conduct research, analyze information, and draw conclusions, fostering independent inquiry and problem-solving skills.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/Academic-icon.png" alt="Professional Development"loading='lazy' decoding='async' />
                                        </div>
                                        <h5>Professional Development</h5>
                                        <p>Writing assignments prepare MBA students for the rigors of professional practice by developing their communication, research, and critical thinking skills.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='benefit-slider-section' id='benefits'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className="benifit-heading">
                                <h2>Benefits of Academic Writing Services for MBA Students</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="slider-container">
                                <Slider ref={sliderRef} {...settings}>
                                    {cards.map((card, index) => (
                                        <div className="slider-card" key={index}>
                                            <img src={card.image} alt={card.heading} className="card-image" loading='lazy' decoding='async'/>
                                            <h4 className="card-heading">{card.heading}</h4>
                                            <p className="card-description">{card.description}</p>
                                            <Link className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="bottom-container">
                                    <div className="custom-arrows">
                                        <button className="custom-arrow" onClick={handlePrevious}>
                                            &#8249;
                                        </button>
                                        <button className="custom-arrow" onClick={handleNext}>
                                            &#8250;
                                        </button>

                                    </div>
                                    <div className='service-links'>
                                        <Link to='/all-services'>All Our Services</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>At Kumbhatech Solutions, we understand this challenge. We are dedicated to providing affordable website design services that empower businesses of all sizes to establish a strong online presence without compromising on quality or functionality.</p>

                                    </div>
                                    <br />
                                    <Link to='' className='benift-btn'>explore More</Link>
                                </div>

                            </div>
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>We believe that every business deserves the opportunity to thrive in the digital world, regardless of their financial constraints.</p>

                                    </div>
                                    <br />
                                    <Link to='' className='benift-btn'>Connect With Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='tab-section' id='importance'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='page-title'>
                                <h2>Importance of Cheap Academic Writing Services for MBA Students</h2>
                                <p>In the competitive landscape of higher education, MBA students often face financial constraints and budget limitations. Cheap academic writing services offer the following advantages:</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-container">
                        <div className='cloud-tab'>
                            <div className="tab-headers">
                                <button
                                    className={`tab-link ${activeTab === 'Tab1' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab1')}
                                >
                                    Affordability <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab2' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab2')}
                                >
                                    Accessibility <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab3' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab3')}
                                >
                                    Quality Assurance<span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab4' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab4')}
                                >
                                    Customization and Flexibility<span>+</span>
                                </button>
                            </div>
                            <div id="Tab1" className={`tab-content ${activeTab === 'Tab1' ? 'active' : ''}`}>
                                <p>Cheap academic writing services make it accessible for MBA students to seek professional assistance with their writing assignments without exceeding their budget.</p>
                            </div>
                            <div id="Tab2" className={`tab-content ${activeTab === 'Tab2' ? 'active' : ''}`}>
                                <p>Affordable academic writing services ensure that all MBA students, regardless of their financial situation, can access the support they need to succeed academically.</p>
                            </div>
                            <div id="Tab3" className={`tab-content ${activeTab === 'Tab3' ? 'active' : ''}`}>
                                <p>Despite their low cost, cheap academic writing services maintain high standards of quality, providing well-researched, original, and professionally written assignments that meet academic requirements.</p>
                            </div>
                            <div id="Tab4" className={`tab-content ${activeTab === 'Tab4' ? 'active' : ''}`}>
                                <p>Cheap academic writing services offer customized solutions tailored to the specific needs and requirements of MBA students, ensuring that assignments are relevant, accurate, and well-written.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="apply-now" id="apply">
                <div className='apply-image-container'>
                    <div className="container" >
                        <div className='col-lg-6 '>
                            <h2 style={{ paddingTop: '30px' }}>KumbhaaTech’s Approach to Cheap Academic Writing Services for MBA Students</h2>
                            <p>At KumbhaaTech, we understand the importance of cost-effective solutions that deliver results. Our approach to academic writing services for MBA students focuses on:</p>
                        </div>
                        <div className="row" >

                            <AcademicChoosing />

                        </div>
                    </div>
                </div>
            </section>

            <section className='fixed-bacground-section'>
                <div className="fixed-background-5">
                    <div className="fixed-background-content">
                        <div className="fixed-background-content-inside">
                            <h2>Ready to Excel in Academic Writing?</h2>
                            <p>Achieve academic success with Kumbhatech Solutions' comprehensive academic writing services. Contact us today for a free consultation and quote. Our team of experienced writers specializes in crafting high-quality academic papers, essays, research papers, and more. Let us support your academic journey with professional writing assistance tailored to your specific requirements!.</p>
                           
                           <br/> 
                           <Link to='' className='benift-btn'>Connect With Us</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="conclusion" id='Conclusion'>
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2 className='conclusion-heading'>conclusion</h2>
                        </div>
                        <div className="col-lg-6">
                            <div className="fist-conclusion-content">
                                <p>In conclusion, academic writing plays a crucial role in the academic success of MBA students pursuing their education overseas. From demonstrating understanding and developing analytical skills to enhancing communication and preparing for professional practice, academic writing supports various aspects of MBA education and career development. Cheap academic writing services offer MBA students a cost-effective solution to their writing needs, ensuring accessibility, affordability, and quality without compromise. Contact KumbhaaTech today to learn more about our cheap academic writing services and take the first step towards academic success</p>
                            </div>
                        </div>
                        <div className="col-lg-6">

                            <div className="second-conclusion-content">
                                <p>By choosing KumbhaaTech, you're partnering with a trusted academic writing service provider that prioritizes affordability, quality, and reliability. Let us help you achieve your academic goals with our cheap academic writing services tailored to meet your unique needs and requirements.</p>
                                <Link className='conclusion-link'>Talk To Expert </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='news-letter'>
                <div className="news-letter-container">

                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6">
                                <div>
                                    <h4>Get Academic Support Today!</h4>
                                    <p>Ready to elevate your academic performance? Contact us now for a consultation and quote. Let Kumbhatech Solutions be your partner in achieving excellence in academic writing!.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='subscribe-btnbox'>
                                    <button>Connect With Us <span>+</span></button></div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </Fragment>
    )
}

export default AcademicWriting;
