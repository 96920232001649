import React, { useState , useEffect} from 'react';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const Services = () => {
  useEffect(() => {
    document.title = ' Affortable Services At KumbhaaTech Solutions';
    return () => {
        document.title = 'leading software company - KumbhaaTech Solutions';
    };
}, []);
  const servicesData = [
    { id: 1,img:'assets/images/KT-Webdevelopment.png',stimg:'assets/images/KT-RD-WEB.gif', title: 'Quality Website Design & Development', description: 'Create stunning, responsive websites that deliver an excellent user experience and reflect your brand\'s identity', link: '/affordable-website-design-services', type: 'technical' },
    { id: 2, img:'assets/images/KT-software-rd.png',stimg:'assets/images/KT-software.gif',title: 'Professional Software Services', description: 'Develop custom software solutions tailored to your business needs, enhancing efficiency and productivity', link: '/affordable-professional-software-services', type: 'technical' },
    { id: 3,img:'assets/images/KT-content.png',stimg:'assets/images/KT-content-rd.gif', title: 'Expert Content Writing', description: 'Deliver engaging, SEO-optimized content that captivates your audience and boosts your search engine rankings. ', link: '/affordable-expert-content-writing', type: 'technical' },
    { id: 4,img:'assets/images/KT-digitalMaketing-red.png',stimg:'assets/images/KT-digitalMaketing.gif',title: 'Strategic Digital Marketing', description: 'Develop comprehensive digital marketing strategies, including social media marketing and email campaigns, to drive traffic and increase conversions', link: '/affordable-strategic-digital-marketing', type: 'technical' },
    { id: 5,img:'assets/images/KT-domain-red.png',stimg:'assets/images/KT-domain.png', title: 'Domain Registration', description: 'Secure your online presence with hassle-free domain registration services.', link: '/domine-registration-services', type: 'technical' },
    { id: 6,img:'assets/images/KT-domain-red.png',stimg:'assets/images/KT-domain.png', title: 'Web Hosting', description: 'Discover our affordable website hosting soluctions designed to meet your neeeds without breaking your budget.', link: '/web-hosting-services', type: 'technical' },
    { id: 7,img:'assets/images/KT-seo-red.png',stimg:'assets/images/KT-seo.gif', title: 'Effective SEO', description: 'Improve your website\'s visibility on search engines with our proven SEO techniques.', link: '/effective-seo-services', type: 'technical' },
    { id: 8,img:'assets/images/KT-academic-writing-red.png',stimg:'assets/images/KT-academic-writing-red.gif', title: 'Comprehensive Academic Writing', description: 'Receive expertly crafted academic papers and research content tailored to your requirements. ', link: '/academic-writing-services', type: 'nontechnical' },
    { id: 9, img:'assets/images/KT-personalised-sop-red.png',stimg:'assets/images/KT-personalised-sop.gif',title: 'Personalized SOP Writing', description: 'Get customized Statements of Purpose (SOPs) that enhance your university applications.', link: '/sop-writing-services', type: 'nontechnical' },
 
   


  ];

  const [selectedFilter, setSelectedFilter] = useState('all');

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  const filteredServices = selectedFilter === 'all' ? servicesData : servicesData.filter(service => service.type === selectedFilter);

  return (
    <>
      <Header />
 
  <div class="custom-banner">
        <div class="service-banner-content">
          <h1>Best Custom Software Development Company <span>for Digital Success</span> </h1>
          <p>Affordable Services for Your Business Needs From website development ,Software Development and digital marketing to SEO, SOP, and assignment writing, we’ve got you covered.</p>
          <br />
          <Link to='' className='abt-btn'>Get Started Today</Link>
        </div>
      </div>
    
      <section className='key-module-bg' style={{ marginTop: "50px" }}>
        <div className="container">
          <div className='features-courseProject'>
            <div className="row">
              <div className="col-lg-12">
                <h2>Affordable Services</h2>
                <br/>
                <h5>Get started today—contact us for a free consultation!</h5>
                <div className="filter-buttons">
                  <button className={selectedFilter === 'all' ? 'active' : ''} onClick={() => handleFilterChange('all')}>All Services</button>
                  <button className={selectedFilter === 'technical' ? 'active' : ''} onClick={() => handleFilterChange('technical')}>Technical Services</button>
                  <button className={selectedFilter === 'nontechnical' ? 'active' : ''} onClick={() => handleFilterChange('nontechnical')}>Non-technical Services</button>
                </div>
              </div>
              <div className="key-modules-container">
                {filteredServices.map(service => (
                  <div key={service.id} className="key-module">
                    <div className='key-image-container'>
                    <img src={service.img} alt="Static" class="static-image" loading='lazy' decoding='async'/>
                    <img src={service.stimg} alt="Animated" class="animated-image"loading='lazy' decoding='async' />
                    </div>
                    <h4>{service.title}</h4>
                    <p>{service.description}</p>
                    <Link to={service.link} className="explore-button">
                      <span className="explore-texts">Explore More</span>
                      <span className="arrows">&rarr;</span>
                    </Link>
                  </div>
                ))}
              </div>
              <div className='col-lg-4'>
              <div  className="key-module">
                    <div className='key-image-container'>
                    <img src='assets/images/KT-admission-red.png' alt="Static" class="static-image" loading='lazy' decoding='async'/>
                    <img src='assets/images/KT-admission.gif' alt="Animated" class="animated-image"loading='lazy' decoding='async' />
                    </div>
                    <h4>University Admission Consulting</h4>
                    <p>Navigate the university admission process with ease, thanks to our expert guidance.</p>
                    <Link  to='/university-admission-services' className="explore-button">
                      <span className="explore-texts">Explore More</span>
                      <span className="arrows">&rarr;</span>
                    </Link>
                  </div>
              </div>
              {/* <a href="https://www.flaticon.com/free-animated-icons/computer" title="computer animated icons">Computer animated icons created by Freepik - Flaticon</a>web 
              <a href="https://www.flaticon.com/free-animated-icons/metadata" title="metadata animated icons">Metadata animated icons created by Freepik - Flaticon</a>soft
              <a href="https://www.flaticon.com/free-animated-icons/article" title="article animated icons">Article animated icons created by Freepik - Flaticon</a>content
              <a href="https://www.flaticon.com/free-animated-icons/content-marketing" title="content marketing animated icons">Content marketing animated icons created by Freepik - Flaticon</a>digital
              <a href="https://www.flaticon.com/free-icons/domain-registration" title="domain registration icons">Domain registration icons created by Freepik - Flaticon</a> domain
              <a href="https://www.flaticon.com/free-animated-icons/cloud-computing" title="cloud computing animated icons">Cloud computing animated icons created by Freepik - Flaticon</a>hosting
              <a href="https://www.flaticon.com/free-animated-icons/seo" title="seo animated icons">Seo animated icons created by Freepik - Flaticon</a>seo
              <a href="https://www.flaticon.com/free-animated-icons/university" title="university animated icons">University animated icons created by Freepik - Flaticon</a>admission
              <a href="https://www.flaticon.com/free-animated-icons/write" title="write animated icons">Write animated icons created by Freepik - Flaticon</a> sop
              academic<a href="https://www.flaticon.com/free-animated-icons/university-book" title="university book animated icons">University book animated icons created by Freepik - Flaticon</a>
              */}
            </div>
          </div>
        </div>
        <br />
        <br />
      </section>
      <section className="careers-section">
        <div className="container">
          <div className="careers-container">
            <div className="careers-content">
              <h1>Discover KumbhaTech</h1>
              <p>Unlock your potential with us. Explore what motivates and empowers you. Find your inspiration.</p>
              <div className='service-links service-link-banner'>
                <Link to='/all-services'> Connect With Us</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Services;