import React, { useRef } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';
import { Link } from "react-router-dom";

function ServicesSlider() {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const cards = [
    {
      image: "assets/images/web-develop&design.webp",
      heading: "Web Design & Development",
      description: "Creating visually appealing and highly functional websites that enhance your online presence and user engagement.",
     
    },
    {
      image: "assets/images/Custom-software-KT.webp",
      heading: "customized software",
      description: "Delivering customized software solutions to streamline your business operations and improve efficiency",
     
    },
    {
      image: "assets/images/content-writing.jpg",
      heading: "Content Writing",
      description: "Producing high-quality, SEO-optimized content that captures your audience's attention and boosts your search engine rankings",
    
    },
    {
      image: "assets/images/Digital-Marketing-KT.webp",
      heading: "Digital Marketing",
      description: "For unique business needs, we offer custom website development solutions tailored to your specific requirements and functionalities.",
      
    },
    {
      image: "assets/images/web-hosting.jpg",
      heading: "Web Hosting",
      description: "For unique business needs, we offer custom website development solutions tailored to your specific requirements and functionalities.",
      
    },
    {
      image: "assets/images/Domine-registration.webp",
      heading: "Domain Registration",
      description: "Secure your online presence with hassle-free domain registration services.",
      
    },
    {
      image: "assets/images/sop-writing.webp",
      heading: "SOP Writing",
      description: "Crafting compelling Statements of Purpose (SOPs) to strengthen your applications to educational institutions abroad.",
      
    }
  ];

  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        {cards.map((card, index) => (
          <div className="slider-card" key={index}>
            <img  src={card.image} alt={card.heading} className="card-image" loading="lazy" />
            <h4 className="card-heading">{card.heading}</h4>
            <p className="card-description">{card.description}</p>
            <Link className="explore-button">
                      <span className="explore-texts">Explore More</span>
                      <span className="arrows">&rarr;</span>
                    </Link>
          </div>
        ))}
      </Slider>
     <div className="bottom-container">
      <div className="custom-arrows">
        <button className="custom-arrow" onClick={handlePrevious}>
        &#8249;
        </button>
        <button className="custom-arrow" onClick={handleNext}>
        &#8250;
        </button>
        
        </div>
        <div className='service-links'>
          <Link to='/all-services'>All Our Services</Link>
        </div>
      </div>
    </div>
  );
}

export default ServicesSlider;
/* <a href="https://www.freepik.com/free-photo/programming-background-collage_34089161.htm#fromView=search&page=1&position=12&uuid=72e44b75-40a2-4ea1-b487-9ebf992ea804">Image by freepik</a>web */
/* <a href="https://www.freepik.com/free-vector/web-development-isometric-concept-composition-illustration_13805027.htm#fromView=search&page=1&position=28&uuid=29c7ee7f-afdd-4c65-ac93-3c41e68caa64">Image by macrovector on Freepik</a>software */
/* <a href="https://www.freepik.com/free-photo/marketing-advertising-commercial-strategy-concept_2758712.htm#fromView=search&page=1&position=41&uuid=08db77df-c6d5-448c-a6c8-29a7f531a52f">Image by rawpixel.com on Freepik</a>markeying */

/* <a href="https://www.freepik.com/free-photo/registration-application-paper-form-concept_16436955.htm#fromView=search&page=1&position=1&uuid=0d7e3f8c-6043-4d2c-ad49-a475602f2b6f">Image by rawpixel.com on Freepik</a> register */