import React, { Fragment, useState, useRef,useEffect } from 'react'
import Header from '../Header';
import Footer from '../Footer';
import SubNavigation from '../OurServices/SubNav.jsx';
import Slider from 'react-slick';
import SeoChoosing from './SeoChoosing.jsx'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../slider.css';
import { Link } from 'react-router-dom';
const SeoService = () => {
    useEffect(() => {
        document.title = 'Effective SEO Services | KumbhaaTech Solutions';
        return () => {
            document.title = 'leading software company - KumbhaaTech Solutions';
        };
    }, []);
    const [activeTab, setActiveTab] = useState('Tab1');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const cards = [
        {
            image: "assets/images/web-trapic.jpg",
            heading: "Increased Website Traffic",
            description: "SEO services drive targeted organic traffic to websites, increasing the number of visitors and potential customers.",

        },
        {
            image: "assets/images/ranking.jpg",
            heading: "Improved Search Engine Ranking",
            description: "SEO services improve website ranking in search engine results pages (SERPs), making it more likely to be found by users searching for relevant keywords or topics.",

        },
        {
            image: "assets/images/enhanced-user.webp",
            heading: "Enhanced User Experience ",
            description: "SEO services optimize website structure and performance, improving user experience and satisfaction, which can lead to higher engagement and conversions.",

        },
        {
            image: "assets/images/sustainbility.webp",
            heading: "Long-Term Sustainability",
            description: "Unlike paid advertising, SEO provides long-term results and sustainability, continuing to drive organic traffic and visibility even after the initial investment.",

        }
    ];

    const handlePrevious = () => {
        sliderRef.current.slickPrev();
    };

    const handleNext = () => {
        sliderRef.current.slickNext();
    };


    return (
        <Fragment>
            <Header />
            <div class="custom-banner">
                <div class="custom-banner-content">
                    <h1>Expert SEO Services at Affordable Prices</h1>
                    <p>Boost your website's visibility with Kumbhaatech Solutions. Our expert SEO services are designed to improve your search engine rankings and drive organic traffic to your site. Get professional SEO services without breaking the bank.</p>
                    <br />
                    <Link to='' className='abt-btn'>Improve Your SEO Today!</Link>
                </div>
            </div>

            <section className='subnav'>
                <div className="container" >
                    <div className='row'>
                        <div className="col-lg-12" >

                            <SubNavigation />
                        </div>
                    </div>
                </div>
            </section>
            <section className="web-development-container">
                <div className="container">
                    <div className="row">
                        <div className="web-heading col-lg-9">
                            <h2>Unveiling the Power of SEO Services: Uses, Benefits, and How KumbhaaTech Provides Affordable Solutions.</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="web-content">
                                <p>In today’s digital landscape, search engine optimization (SEO) has emerged as a fundamental component of any successful online strategy. It's the key to improving website visibility, driving organic traffic, and ultimately, achieving business growth. </p>
                                <p>At KumbhaaTech, we understand the importance of cost-effective SEO solutions. That's why we provide cheap SEO services tailored to meet the diverse needs of businesses in the market.</p>
                                <button className="btn btn-primary">Learn More</button>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="image-container">
                                <img src="assets/images/Seo-KT.jpg" alt="SEO Services" decoding='async' loading='lazy' />
                                {/* <a href="https://www.freepik.com/free-photo/searching-engine-optimizing-seo-browsing-concept_3533298.htm#fromView=search&page=1&position=6&uuid=9f82349f-7a6f-4f83-925b-4e92697dabf0">Image by rawpixel.com on Freepik</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="web-section" id='understanding'>
                <div class="container">

                    <div class="web-container align-items-center">
                        <div class="col-lg-5 web-img">
                            <img src="assets/images/Seo-web.jpg" alt="seo" decoding='async' loading='lazy'/>
                        </div>
                        <div class="col-lg-7 web-content">
                            <h2>Understanding SEO Services</h2>
                            <p>SEO services encompass a range of strategies and tactics aimed at improving a website's visibility and ranking in search engine results pages (SERPs). From on-page optimization and content creation to link building and technical SEO, SEO services are designed to enhance a website's relevance and authority in the eyes of search engines like Google, Bing, and Yahoo.</p>

                            <button className='main-button'> Get Appoinment Now!</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="info-section" id="purpose">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">The Purpose of SEO Services</h2>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Increasing Visibility</h3>
                                    <p>SEO services help websites rank higher in search engine results, making them more visible to users searching for relevant topics, products, or services.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Driving Organic Traffic</h3>
                                    <p>By improving website visibility and ranking, SEO services drive organic traffic to websites, increasing the likelihood of conversions and sales.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Building Authority</h3>
                                    <p>SEO services help websites build authority and credibility in their respective industries or niches, earning trust and recognition from both users and search engines.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Maximizing ROI</h3>
                                    <p>By driving targeted organic traffic and increasing conversion rates, SEO services offer a high return on investment (ROI) compared to traditional advertising or marketing channels.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">Uses of SEO Services</h2>
                                    <p>SEO services are essential for various online endeavors, including:</p>
                                </div>
                            </div>
                            <div className="info-card-with-icon-inner">
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/digital-marketing-icon.png" alt=" Websites optimization"decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Website Optimization</h5>
                                        <p>SEO services optimize website structure, content, and performance to improve search engine visibility and user experience.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/digital-marketing-icon.png" alt="keyword research" decoding='async' loading='lazy'/>
                                        </div>
                                        <h5>Keyword Research</h5>
                                        <p>SEO services identify relevant keywords and phrases with high search volume and low competition, optimizing website content for better ranking.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/digital-marketing-icon.png" alt="link building" decoding='async' loading='lazy'/>
                                        </div>
                                        <h5>Link Building</h5>
                                        <p>SEO services build quality backlinks from reputable websites to improve website authority and credibility in the eyes of search engines.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/digital-marketing-icon.png" alt="local seo" decoding='async' loading='lazy'/>
                                        </div>
                                        <h5>Local SEO</h5>
                                        <p>SEO services optimize websites for local search, ensuring visibility in local search results and directories for users searching for nearby businesses or services.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='benefit-slider-section' id='benefits'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className="benifit-heading">
                                <h2>Benefits of SEO Services</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="slider-container">
                                <Slider ref={sliderRef} {...settings}>
                                    {cards.map((card, index) => (
                                        <div className="slider-card" key={index}>
                                            <img src={card.image} alt={card.heading} className="card-image" decoding='async' loading='lazy'/>
                                            <h4 className="card-heading">{card.heading}</h4>
                                            <p className="card-description">{card.description}</p>
                                            <Link className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="bottom-container">
                                    <div className="custom-arrows">
                                        <button className="custom-arrow" onClick={handlePrevious}>
                                            &#8249;
                                        </button>
                                        <button className="custom-arrow" onClick={handleNext}>
                                            &#8250;
                                        </button>

                                    </div>
                                    <div className='service-links'>
                                        <Link to='/all-services'>All Our Services</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>At Kumbhatech Solutions, we understand this challenge. We are dedicated to providing affordable website design services that empower businesses of all sizes to establish a strong online presence without compromising on quality or functionality.</p>

                                    </div>
                                    <br />
                                    <Link to='' className='benift-btn'>explore More</Link>
                                </div>

                            </div>
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>We believe that every business deserves the opportunity to thrive in the digital world, regardless of their financial constraints.</p>

                                    </div>
                                    <br />
                                    <Link to='' className='benift-btn'>Connect With Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='tab-section' id='importance'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='page-title'>
                                <h2>Importance of Cheap SEO Services</h2>
                                <p>In today’s competitive market, businesses must prioritize cost-effective solutions without compromising quality. Cheap SEO services offer the following advantages:</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-container">
                        <div className='cloud-tab'>
                            <div className="tab-headers">
                                <button
                                    className={`tab-link ${activeTab === 'Tab1' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab1')}
                                >
                                    Affordability <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab2' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab2')}
                                >
                                    Scalability <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab3' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab3')}
                                >
                                    High ROI<span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab4' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab4')}
                                >
                                    Competitive Edge<span>+</span>
                                </button>
                            </div>
                            <div id="Tab1" className={`tab-content ${activeTab === 'Tab1' ? 'active' : ''}`}>
                                <p>Cheap SEO services make it accessible for businesses of all sizes to invest in effective search engine optimization strategies that drive results.</p>
                            </div>
                            <div id="Tab2" className={`tab-content ${activeTab === 'Tab2' ? 'active' : ''}`}>
                                <p>Affordable SEO services can scale alongside businesses, accommodating increased optimization needs and demands without incurring significant additional costs.</p>
                            </div>
                            <div id="Tab3" className={`tab-content ${activeTab === 'Tab3' ? 'active' : ''}`}>
                                <p>Despite their low cost, cheap SEO services offer a high return on investment (ROI) by driving targeted organic traffic, increasing visibility, and improving conversion rates.</p>
                            </div>
                            <div id="Tab4" className={`tab-content ${activeTab === 'Tab4' ? 'active' : ''}`}>
                                <p>By minimizing upfront investment and ongoing costs, cheap SEO services provide businesses with a competitive edge, allowing them to allocate resources to other strategic initiatives.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="apply-now" id="approch">
                <div className='apply-image-container'>
                    <div className="container" >
                        <div className='col-lg-6 '>
                            <h2 style={{ paddingTop: '30px' }}>KumbhaaTech’s Approach to Cheap SEO Services</h2>
                            <p style={{ color: 'white' }}>At KumbhaaTech, we understand the importance of cost-effective solutions that deliver results. Our approach to SEO services focuses on:</p>
                        </div>
                        <div className="row" >

                            <SeoChoosing />

                        </div>
                    </div>
                </div>
            </section>

            <section className='key-module-bg' style={{ marginTop: "50px" }}>
                <div class="container">
                    <div className='features-courseProject'>
                        <div class="row">
                            <div class="col-lg-6">
                                <h2>Our Relateed Services</h2>
                            </div>
                            <div class="key-modules-container">

                                <div class="key-module">

                                    <h4>Content Writing</h4>
                                    <div class="module-content">
                                        <p>Unlock the full potential of your brand with our comprehensive digital marketing strategies, driving targeted traffic and maximizing conversion rates.</p>
                                        <button className="explore-button">
                                            <span className="explore-texts">Explore More</span>
                                            <span className="arrows">&rarr;</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="key-module">

                                    <h4>Web Design</h4>
                                    <div class="module-content">
                                        <p>We design your website with scalability in mind, allowing you to easily add new features and functionalities as your business grows.</p>
                                        <button className="explore-button">
                                            <span className="explore-texts">Explore More</span>
                                            <span className="arrows">&rarr;</span>
                                        </button>
                                    </div>
                                </div>

                                <div class="key-module">

                                    <h4>Web Hosting</h4>
                                    <div class="module-content">
                                        <p>Suspendisse tempor mauris a sem elementum bibendum. Praesent facilisis massa non vestibulum.</p>
                                        <button className="explore-button">
                                            <span className="explore-texts">Explore More</span>
                                            <span className="arrows">&rarr;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </section>
            <section className='fixed-bacground-section'>
                <div className="fixed-background-2">
                    <div className="fixed-background-content">
                        <div className="fixed-background-content-inside">
                            <h2>Ready to Boost Your Search Engine Rankings?</h2>
                            <p>Maximize your visibility and drive organic traffic with Kumbhatech Solutions' SEO services. Contact us today for a free consultation and quote. Our SEO experts will analyze your website, develop customized strategies, and implement proven tactics to improve your search engine rankings. Discover how our comprehensive SEO solutions can help you achieve sustainable growth online!.</p>
                           
                           <br/> 
                           <Link to='' className='benift-btn'>Connect With Us</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="conclusion" id='Conclusion'>
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2 className='conclusion-heading'>conclusion</h2>
                        </div>
                        <div className="col-lg-6">
                            <div className="fist-conclusion-content">
                                <p>In conclusion, SEO services are essential for improving website visibility, driving organic traffic, and achieving business growth in today’s digital age. From increasing website ranking to enhancing user experience and maximizing ROI, SEO offers numerous benefits for businesses looking to succeed online. Cheap SEO services provide businesses with a cost-effective solution to their optimization needs, ensuring accessibility and affordability without compromising on quality. Contact KumbhaaTech today to learn more about our cheap SEO services and take your website to new heights.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">

                            <div className="second-conclusion-content">
                                <p>By choosing KumbhaaTech, you're partnering with a trusted SEO provider that prioritizes affordability, quality, and performance. Let us help you achieve your online goals with our cheap SEO services tailored to meet your unique needs and objectives.</p>
                                <Link className='conclusion-link'>Talk To Expert </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='news-letter'>
                <div className="news-letter-container">

                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6">
                                <div>
                                    <h4>Get Started Today!</h4>
                                    <p>Ready to dominate search engine results? Contact us now for a personalized SEO consultation and quote. Let Kumbhatech Solutions drive your business to the top of search engine rankings!.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='subscribe-btnbox'>
                                    <button>Connect With Us <span>+</span></button></div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </Fragment>
    )
}

export default SeoService;
