import React, { Fragment, useState, useRef ,useEffect} from 'react'
import Header from '../Header';
import Footer from '../Footer';
import SubNavigation from '../OurServices/SubNav.jsx';
import Slider from 'react-slick';
import DomineChoosing from './DomineChoosing.jsx'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../slider.css';
import { Link } from 'react-router-dom';
const DomineRegistration = () => {
    useEffect(() => {
        document.title = 'Domine Registration Services | KumbhaaTech Solutions';
        return () => {
            document.title = 'leading software company - KumbhaaTech Solutions';
        };
    }, []);
    const [activeTab, setActiveTab] = useState('Tab1');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const cards = [
        {
            image: "assets/images/branding.jpg",
            heading: "Brand Identity",
            description: "A custom domain name helps build brand identity and credibility, distinguishing your website from competitors and strengthening brand recognition.",

        },
        {
            image: "assets/images/Online-Presence.webp",
            heading: "Online Presence",
            description: "Domain registration establishes your online presence, making your website accessible to users worldwide and expanding your reach.",

        },
        {
            image: "assets/images/Business-web.webp",
            heading: "Professionalism ",
            description: "A custom domain name adds a professional touch to your online presence, enhancing your reputation and trustworthiness in the eyes of visitors.",

        },
        {
            image: "assets/images/ranking.jpg",
            heading: "Search Engine Visibility",
            description: "A relevant and keyword-rich domain name can improve your website's search engine visibility, making it easier for users to find and discover your site online.",

        }
    ];

    const handlePrevious = () => {
        sliderRef.current.slickPrev();
    };

    const handleNext = () => {
        sliderRef.current.slickNext();
    };


    return (
        <Fragment>
            <Header />
            <div class="custom-banner">
                <div class="custom-banner-content">
                    <h1>Domain Registration Services at the Best Price</h1>
                    <p>Secure your online identity with Kumbhaatech Solutions. We offer reliable and affordable domain registration services to help you establish a strong online presence. Find your perfect domain name today.</p>
                    <br />
                    <Link to='' className='abt-btn'>Register Your Domain Now!</Link>
                </div>
            </div>
            <section className='subnav'>
                <div className="container" >
                    <div className='row'>
                        <div className="col-lg-12" >

                            <SubNavigation />
                        </div>
                    </div>
                </div>
            </section>
            <section className="web-development-container">
                <div className="container">
                    <div className="row">
                        <div className="web-heading col-lg-10">
                            <h2>Unveiling the Importance of Domain Registration: Uses, Benefits, and How KumbhaaTech Provides Affordable Solutions.</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="web-content">
                                <p>In the digital landscape, your domain name is your online identity. It's the gateway through which users access your website, and it plays a crucial role in shaping your brand's online presence.</p>
                                <p> At KumbhaaTech, we understand the significance of securing the right domain name for your business. That's why we provide cheap domain registration services, ensuring that businesses of all sizes can establish their online presence without breaking the bank.</p>
                                <button className="btn btn-primary">Learn More</button>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="image-container">
                                <img src="assets/images/domine-register.webp" alt="Domain Registration" decoding='async' loading='lazy' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="web-section" id='understanding'>
                <div class="container">

                    <div class="web-container align-items-center">
                        <div class="col-lg-5 web-img">
                            <img src="assets/images/domine-vector.jpg" alt="Understanding Domain Registration" decoding='async' loading='lazy'/>
                        </div>
                        <div class="col-lg-7 web-content">
                            <h2>Understanding Domain Registration</h2>
                            <p>Domain registration is the process of acquiring a unique web address (domain name) for your website, allowing users to find and access your site on the internet. It involves choosing a domain name, checking its availability, and registering it through a domain registrar.</p>

                            <button className='main-button'> Get Appoinment Now!</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="info-section" id="purpose">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">The Purpose of Domain Registration</h2>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Establishing Online Identity</h3>
                                    <p>A domain name serves as your unique online identity, representing your brand, business, or personal website on the internet.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Creating Credibility</h3>
                                    <p>A custom domain name adds credibility and professionalism to your online presence, instilling trust in visitors and potential customers.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Enhancing Brand Recognition</h3>
                                    <p>A memorable and relevant domain name helps users remember and recognize your brand, increasing brand visibility and recall.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Enabling Website Accessibility</h3>
                                    <p>Domain registration ensures that your website is accessible to users worldwide, allowing them to find and access your site via the internet.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">Uses of Domain Registration</h2>
                                    <p>Domain registration is essential for various online endeavors, including:</p>
                                </div>
                            </div>
                            <div className="info-card-with-icon-inner">
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/dime-icon.png" alt="Business Websites" decoding='async' loading='lazy'/>
                                        </div>
                                        <h5>Business Websites</h5>
                                        <p>Businesses register domain names to establish their online presence, showcase products or services, and attract customers.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/dime-icon.png" alt="personal website" decoding='async' loading='lazy'/>
                                        </div>
                                        <h5>Personal Websites</h5>
                                        <p>Individuals register domain names for personal blogs, portfolios, or online resumes, creating a unique online identity.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/dime-icon.png" alt="e commersePlatforms" decoding='async' loading='lazy'/>
                                        </div>
                                        <h5>E-commerce Platforms</h5>
                                        <p>E-commerce websites register domain names to sell products or services online, attracting customers and driving sales.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/dime-icon.png" alt="Branding and Marketing"decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Branding and Marketing</h5>
                                        <p>Domain registration supports branding and marketing efforts by providing a memorable and relevant web address for promotional campaigns.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='benefit-slider-section' id='benefits'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className="benifit-heading">
                                <h2>Benefits of Domain Registration</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="slider-container">
                                <Slider ref={sliderRef} {...settings}>
                                    {cards.map((card, index) => (
                                        <div className="slider-card" key={index}>
                                            <img src={card.image} alt={card.heading} className="card-image" decoding='async' loading='lazy'/>
                                            <h4 className="card-heading">{card.heading}</h4>
                                            <p className="card-description">{card.description}</p>
                                            <Link className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="bottom-container">
                                    <div className="custom-arrows">
                                        <button className="custom-arrow" onClick={handlePrevious}>
                                            &#8249;
                                        </button>
                                        <button className="custom-arrow" onClick={handleNext}>
                                            &#8250;
                                        </button>

                                    </div>
                                    <div className='service-links'>
                                        <Link to='/all-services'>All Our Services</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>At Kumbhatech Solutions, we understand this challenge. We are dedicated to providing affordable website design services that empower businesses of all sizes to establish a strong online presence without compromising on quality or functionality.</p>

                                    </div>
                                    <br />
                                    <Link to='' className='benift-btn'>explore More</Link>
                                </div>

                            </div>
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>We believe that every business deserves the opportunity to thrive in the digital world, regardless of their financial constraints.</p>

                                    </div>
                                    <br />
                                    <Link to='' className='benift-btn'>Connect With Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='tab-section' id='importance'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='page-title'>
                                <h2>Importance of Cheap Domain Registration</h2>
                                <p>In today’s competitive market, businesses must prioritize cost-effective solutions without compromising quality. Cheap domain registration services offer the following advantages:</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-container">
                        <div className='cloud-tab'>
                            <div className="tab-headers">
                                <button
                                    className={`tab-link ${activeTab === 'Tab1' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab1')}
                                >
                                    Affordability <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab2' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab2')}
                                >
                                    Scalability <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab3' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab3')}
                                >
                                    Brand Protection<span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab4' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab4')}
                                >
                                    Competitive Edge<span>+</span>
                                </button>
                            </div>
                            <div id="Tab1" className={`tab-content ${activeTab === 'Tab1' ? 'active' : ''}`}>
                                <p>Cheap domain registration services make it accessible for businesses of all sizes to secure a custom domain name without breaking the bank.</p>
                            </div>
                            <div id="Tab2" className={`tab-content ${activeTab === 'Tab2' ? 'active' : ''}`}>
                                <p>Affordable domain registration services can scale alongside businesses, accommodating increased online presence and expansion without incurring significant additional costs.</p>
                            </div>
                            <div id="Tab3" className={`tab-content ${activeTab === 'Tab3' ? 'active' : ''}`}>
                                <p>Cheap domain registration services allow businesses to secure multiple domain extensions and variations, protecting their brand identity and preventing competitors from acquiring similar domain names.</p>
                            </div>
                            <div id="Tab4" className={`tab-content ${activeTab === 'Tab4' ? 'active' : ''}`}>
                                <p>By minimizing upfront investment and ongoing costs, cheap domain registration services provide businesses with a competitive edge, allowing them to allocate resources to other strategic initiatives.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="apply-now" id="approch">
                <div className='apply-image-container'>
                    <div className="container" >
                        <div className='col-lg-6 '>
                            <h2 style={{ paddingTop: '30px' }}>KumbhaaTech’s Approach to Cheap Domain Registration</h2>
                            <p style={{ color: 'white' }}>At KumbhaaTech, we understand the importance of cost-effective solutions that deliver results. Our approach to domain registration focuses on:</p>
                        </div>
                        <div className="row" >

                            <DomineChoosing />

                        </div>
                    </div>
                </div>
            </section>

            <section className='key-module-bg' style={{ marginTop: "50px" }}>
                <div class="container">
                    <div className='features-courseProject'>
                        <div class="row">
                            <div class="col-lg-6">
                                <h2>Our Relateed Services</h2>
                            </div>
                            <div class="key-modules-container">

                                <div class="key-module">

                                    <h4>Content Writing</h4>
                                    <div class="module-content">
                                        <p>Unlock the full potential of your brand with our comprehensive digital marketing strategies, driving targeted traffic and maximizing conversion rates.</p>
                                        <button className="explore-button">
                                            <span className="explore-texts">Explore More</span>
                                            <span className="arrows">&rarr;</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="key-module">

                                    <h4>Web Design</h4>
                                    <div class="module-content">
                                        <p>We design your website with scalability in mind, allowing you to easily add new features and functionalities as your business grows.</p>
                                        <button className="explore-button">
                                            <span className="explore-texts">Explore More</span>
                                            <span className="arrows">&rarr;</span>
                                        </button>
                                    </div>
                                </div>

                                <div class="key-module">

                                    <h4>Web Hosting</h4>
                                    <div class="module-content">
                                        <p>Suspendisse tempor mauris a sem elementum bibendum. Praesent facilisis massa non vestibulum.</p>
                                        <button className="explore-button">
                                            <span className="explore-texts">Explore More</span>
                                            <span className="arrows">&rarr;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </section>

            <section className='fixed-bacground-section'>
                <div className="fixed-background-5">
                    <div className="fixed-background-content">
                        <div className="fixed-background-content-inside">
                            <h2>Ready to Secure Your Online Identity?</h2>
                            <p>Ensure your online presence starts with the right domain name. Contact Kumbhatech Solutions for domain registration services. We provide a seamless process to help you secure your desired domain quickly and affordably. Let us assist you in establishing a strong online identity that aligns with your business goals!.</p>
                            <br/>
                            <Link to='' className='benift-btn'>Connect With Us</Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="conclusion" id='Conclusion'>
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2 className='conclusion-heading'>conclusion</h2>
                        </div>
                        <div className="col-lg-6">
                            <div className="fist-conclusion-content">
                                <p>In conclusion, domain registration is a fundamental step in establishing your online presence and building brand identity on the internet. A custom domain name not only enhances credibility and professionalism but also plays a crucial role in brand recognition and search engine visibility. Cheap domain registration services offer businesses a cost-effective solution to securing their online identity, ensuring accessibility and affordability without compromising on quality. Contact KumbhaaTech today to learn more about our cheap domain registration services and take the first step towards establishing your online presence.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">

                            <div className="second-conclusion-content">
                                <p>By choosing KumbhaaTech, you're partnering with a trusted domain registrar that prioritizes affordability, security, and customer satisfaction. Let us help you secure the perfect domain name for your business at a price that fits your budget.</p>
                                <Link className='conclusion-link'>Talk To Expert </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='news-letter'>
                <div className="news-letter-container">

                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6">
                                <div>
                                    <h4>Get Your Domain Today!</h4>
                                    <p>Ready to claim your online presence? Contact us now to register your domain with ease. Let Kumbhatech Solutions be your trusted partner in building a strong online foundation for your business!.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='subscribe-btnbox'>
                                    <button>Connect With Us <span>+</span></button></div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </Fragment>
    )
}

export default DomineRegistration;
/* <a href="https://www.freepik.com/free-photo/corporate-management-strategy-solution-branding-concept_28096443.htm#fromView=search&page=1&position=0&uuid=ff2715e4-6f8d-4035-9ed7-c0aeecfb5dd2">Image by rawpixel.com on Freepik</a>online */
/* <a href="https://www.freepik.com/free-photo/young-woman-working-laptop-isolated-white-background_8907406.htm#fromView=search&page=1&position=19&uuid=60ca384b-cb1a-41d2-af00-7881005c1521">Image by diana.grytsku on Freepik</a> profess */