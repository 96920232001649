import React, { Fragment, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';

const Project = () => {
    useEffect(() => {
        document.title = 'Our Projects| KumbhaaTech Solutions';
        return () => {
            document.title = 'leading software company - KumbhaaTech Solutions';
        };
    }, []);
    return (
        <Fragment>
            <Header />
            {/* <section className="heading-page header-text" id="top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h6>Here are our upcoming meetings</h6>
                            <h2>Upcoming Meetings</h2>
                        </div>
                    </div>
                </div>
            </section> */}
            {/*  <div className="gallery">
                <div className="container">
                    
                        <div className="desktop-container">
                            <img src="assets/images/desktop.png" alt="Desktop Image" className="desktop" />
                            <div className="website-images">
                                <img src="assets/images/Screenshot (197).png" alt="Website 1" className="website auto-scroll" />
                            </div>
                        </div>
                        <div className="desktop-container">
                            <img src="assets/images/desktop.png" alt="Desktop Image" className="desktop" />
                            <div className="website-images">
                                <img src="assets/images/Screenshot (197).png" alt="Website 1" className="website auto-scroll" />
                            </div>
                        </div>
                        <div className="desktop-container">
                            <img src="assets/images/desktop.png" alt="Desktop Image" className="desktop" />
                            <div className="website-images">
                                <img src="assets/images/Screenshot (197).png" alt="Website 1" className="website auto-scroll" />
                            </div>
                        
                    </div>
                </div>
            </div> */}
         
            <section className="heading-page header-text" id="top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h6>Here are our upcoming meetings</h6>
                            <h2>Upcoming Meetings</h2>
                        </div>
                    </div>
                </div>
            </section>
            <div className="gallery">
                <div className="container">
                    <div className='gallery-item'>
                        <div className="desktop-container">
                            <img src="assets/images/desktop.png" alt="Desktop Image" className="desktop" />
                            <div className="website-images">
                                <img src="assets/images/Screenshot (197).png" alt="Website 1" className="website auto-scroll" />
                            </div>
                        </div>
                       <div className="mobile-container">
                            <img src="assets/images/mobile.png" alt="Mobile Image" className="mobile" />
                            <div className="website-images-mobile">
                                <img src="assets/images/Screenshot (198).png" alt="Website 1" className="website auto-scroll" />
                            </div>
                        </div> 
                    </div>
                    <div className='gallery-item'>
                        <div className="desktop-container">
                            <img src="assets/images/desktop.png" alt="Desktop Image" className="desktop" />
                            <div className="website-images">
                                <img src="assets/images/Screenshot (197).png" alt="Website 1" className="website auto-scroll" />
                            </div>
                        </div>
                        <div className="mobile-container">
                            <img src="assets/images/mobile.png" alt="Mobile Image" className="mobile" />
                            <div className="website-images-mobile">
                                <img src="assets/images/Screenshot (198).png" alt="Website 1" className="website " />
                            </div>
                        </div>
                    </div>
                    <div className='gallery-item'>
                        <div className="desktop-container">
                            <img src="assets/images/desktop.png" alt="Desktop Image" className="desktop" />
                            <div className="website-images">
                                <img src="assets/images/Screenshot (197).png" alt="Website 1" className="website auto-scroll" />
                            </div>
                        </div>
                        <div className="mobile-container">
                            <img src="assets/images/mobile.png" alt="Mobile Image" className="mobile" />
                            <div className="website-images-mobile">
                                <img src="assets/images/Screenshot (198).png" alt="Website 1" className="website auto-scroll" />
                            </div>
                        </div>
                    </div>
                    {/* Add more gallery items here */}
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default Project;
