import React, { Fragment, useState, useRef ,useEffect} from 'react'
import Header from '../Header';
import Footer from '../Footer';
import SubNavigation from '../OurServices/SubNav.jsx';
import Slider from 'react-slick';
import HostingChoosing from './HostingChoosing.jsx'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../slider.css';
import { Link } from 'react-router-dom';
const WebHosting = () => {
    useEffect(() => {
        document.title = 'Web Hosting Services | KumbhaaTech Solutions';
        return () => {
            document.title = 'leading software company - KumbhaaTech Solutions';
        };
    }, []);
    const [activeTab, setActiveTab] = useState('Tab1');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const cards = [
        {
            image: "assets/images/Cost-effectively.webp",
            heading: "Cost-Effectiveness",
            description: "Affordable web hosting services provide high-quality hosting solutions at a low cost, making it accessible for businesses and individuals with limited budgets.",

        },
        {
            image: "assets/images/scalibility-web.webp",
            heading: "Scalability",
            description: "Affordable hosting plans offer scalable options, allowing users to upgrade their resources as their website traffic and content grow without incurring significant additional costs.",

        },
        {
            image: "assets/images/uptime.webp",
            heading: "Reliability and Uptime ",
            description: "Despite their low cost, affordable web hosting services provide reliable performance and high uptime, ensuring that websites remain accessible to users at all times.",

        },
        {
            image: "assets/images/Business-web.webp",
            heading: "Customer Support",
            description: "Affordable web hosting services often include customer support to assist users with technical issues, ensuring a smooth and hassle-free hosting experience.",

        }
    ];

    const handlePrevious = () => {
        sliderRef.current.slickPrev();
    };

    const handleNext = () => {
        sliderRef.current.slickNext();
    };


    return (
        <Fragment>
            <Header />
            <div class="custom-banner">
                <div class="custom-banner-content">
                    <h1>Web Hosting Services at the Best Price</h1>
                    <p>

                        Securing your online identity is crucial, and Kumbhaatech Solutions specializes in providing reliable and affordable Web Hosting services.Whether you're starting a new venture or looking to enhance your current online presence, we offer solutions tailored to meet your needs. Explore our range of domain names to find the perfect match for your business or personal website.</p>
                    <br />
                    <Link to='' className='abt-btn'>Register Your Domain Now!</Link>
                </div>
            </div>
            <section className='subnav'>
                <div className="container" >
                    <div className='row'>
                        <div className="col-lg-12" >

                            <SubNavigation />
                        </div>
                    </div>
                </div>
            </section>
            <section className="web-development-container">
                <div className="container">
                    <div className="row">
                        <div className="web-heading col-lg-10">
                            <h2>Unlocking the Power of Affordable Web Hosting: Uses, Benefits, and Why KumbhaaTech is Your Best Choice.</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="web-content">
                                <p>In the digital age, having a robust online presence is crucial for businesses and individuals alike. A key component of establishing this presence is reliable web hosting. However, finding a web hosting service that is both high-quality and affordable can be challenging. </p>
                                <p> At KumbhaaTech, we understand the importance of cost-effective solutions that don't compromise on performance. That's why we offer affordable web hosting services designed to meet the diverse needs of our clients.</p>
                                <button className="btn btn-primary">Learn More</button>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="image-container">
                                <img src="assets/images/web-hosting.webp" alt="web hosting" decoding='async' loading='lazy' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="web-section" id='understanding'>
                <div class="container">

                    <div class="web-container align-items-center">
                        <div class="col-lg-5 web-img">
                            <img src="assets/images/domine-vector.jpg" alt="Understanding Domain Registration" decoding='async' loading='lazy' />
                        </div>
                        <div class="col-lg-7 web-content">
                            <h2>Understanding Affordable Web Hosting</h2>
                            <p>Web hosting is a service that allows individuals and organizations to make their websites accessible via the internet. Web hosting companies provide the technology and server space needed to store website files and make them available to online users. Affordable web hosting refers to hosting services that offer reliable performance and features at a low cost.</p>

                            <button className='main-button'> Get Appoinment Now!</button>
                           {/*  {isPopupVisible && (
                                <form className="appointment-form" onSubmit={handleSubmit}>
            <h2>Book an Appointment</h2>

            <div className="form-group">
                <label htmlFor="name">Your Name:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                />
                {errors.name && <span className="error">{errors.name}</span>}
            </div>

            <div className="form-group">
                <label htmlFor="email">Email Address:</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                />
                {errors.email && <span className="error">{errors.email}</span>}
            </div>

            <div className="form-group">
                <label htmlFor="phone">Phone Number:</label>
                <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
            </div>

            <div className="form-group">
                <label htmlFor="date">Preferred Date:</label>
                <input
                    type="date"
                    id="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                />
                {errors.date && <span className="error">{errors.date}</span>}
            </div>

            <div className="form-group">
                <label htmlFor="time">Preferred Time:</label>
                <input
                    type="time"
                    id="time"
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                />
                {errors.time && <span className="error">{errors.time}</span>}
            </div>

            <div className="form-group">
                <label htmlFor="message">Additional Notes:</label>
                <textarea
                    id="message"
                    name="message"
                    rows="4"
                    value={formData.message}
                    onChange={handleChange}
                ></textarea>
            </div>

            <button type="submit">Submit Appointment Request</button>
        </form>
                            )} */}
                        </div>
                        
                    </div>
                </div>
            </section>
            <section className="info-section" id="purpose">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">The Purpose of Affordable Web Hosting</h2>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Making Websites Accessible</h3>
                                    <p>The primary purpose of web hosting is to make websites accessible to users on the internet. Hosting services provide the necessary infrastructure to store and deliver website content to visitors.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Ensuring Performance and Reliability</h3>
                                    <p>Web hosting services ensure that websites perform well and remain accessible 24/7, providing reliable uptime and fast loading speeds.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Supporting Website Growth</h3>
                                    <p>Affordable web hosting allows businesses and individuals to scale their online presence as their website traffic and content grow, providing flexible and scalable solutions.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Providing Security</h3>
                                    <p>Web hosting services offer security features such as SSL certificates, firewalls, and regular backups to protect websites from cyber threats and data loss.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">Uses of Affordable Web Hosting</h2>
                                    <p>Affordable web hosting services are essential for various online endeavors, including:</p>
                                </div>
                            </div>
                            <div className="info-card-with-icon-inner">
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/dime-icon.png" alt="Business Websites" decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Business Websites</h5>
                                        <p>Businesses of all sizes use web hosting services to create and maintain their online presence, showcase products and services, and engage with customers.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/dime-icon.png" alt="personal website" decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Personal Websites</h5>
                                        <p>Individuals use web hosting services to share their thoughts, experiences, and expertise through personal blogs and websites.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/dime-icon.png" alt="e commersePlatforms" decoding='async' loading='lazy' />
                                        </div>
                                        <h5>E-commerce Platforms</h5>
                                        <p>E-commerce websites require reliable hosting to manage online transactions, product catalogs, and customer data securely.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/dime-icon.png" alt="Branding and Marketing" decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Portfolio Sites</h5>
                                        <p>Artists, photographers, and professionals use web hosting to create portfolio websites that showcase their work and attract potential clients.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='benefit-slider-section' id='benefits'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className="benifit-heading">
                                <h2>Benefits of Affordable Web Hosting</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="slider-container">
                                <Slider ref={sliderRef} {...settings}>
                                    {cards.map((card, index) => (
                                        <div className="slider-card" key={index}>
                                            <img src={card.image} alt={card.heading} className="card-image" decoding='async' loading='lazy' />
                                            <h4 className="card-heading">{card.heading}</h4>
                                            <p className="card-description">{card.description}</p>
                                            <Link className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="bottom-container">
                                    <div className="custom-arrows">
                                        <button className="custom-arrow" onClick={handlePrevious}>
                                            &#8249;
                                        </button>
                                        <button className="custom-arrow" onClick={handleNext}>
                                            &#8250;
                                        </button>

                                    </div>
                                    <div className='service-links'>
                                        <Link to='/all-services'>All Our Services</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>At Kumbhatech Solutions, we understand this challenge. We are dedicated to providing affordable website design services that empower businesses of all sizes to establish a strong online presence without compromising on quality or functionality.</p>

                                    </div>
                                    <br />
                                    <Link to='' className='benift-btn'>explore More</Link>
                                </div>

                            </div>
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>We believe that every business deserves the opportunity to thrive in the digital world, regardless of their financial constraints.</p>

                                    </div>
                                    <br />
                                    <Link to='' className='benift-btn'>Connect With Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='tab-section' id='importance'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='page-title'>
                                <h2>Importance of Cheap Web Hosting</h2>
                                <p>In today's digital world, having an online presence is crucial for businesses and individuals alike. However, high hosting costs can be a barrier for many. Cheap web hosting services offer the following advantages:</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-container">
                        <div className='cloud-tab'>
                            <div className="tab-headers">
                                <button
                                    className={`tab-link ${activeTab === 'Tab1' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab1')}
                                >
                                    Accessibility<span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab2' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab2')}
                                >
                                    Quality Assurance <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab3' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab3')}
                                >
                                    Flexibility and Scalability<span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab4' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab4')}
                                >
                                    Competitive Edge<span>+</span>
                                </button>
                            </div>
                            <div id="Tab1" className={`tab-content ${activeTab === 'Tab1' ? 'active' : ''}`}>
                                <p>Cheap web hosting services make it accessible for small businesses, startups, and individuals to establish an online presence without breaking the bank.</p>
                            </div>
                            <div id="Tab2" className={`tab-content ${activeTab === 'Tab2' ? 'active' : ''}`}>
                                <p>Despite their low cost, cheap web hosting services maintain high standards of quality, providing reliable performance, security, and customer support.</p>
                            </div>
                            <div id="Tab3" className={`tab-content ${activeTab === 'Tab3' ? 'active' : ''}`}>
                                <p>Affordable web hosting services offer flexible and scalable solutions, allowing users to upgrade their hosting plans as their website grows and their needs evolve.</p>
                            </div>
                            <div id="Tab4" className={`tab-content ${activeTab === 'Tab4' ? 'active' : ''}`}>
                                <p>By minimizing upfront investment and ongoing costs, cheap web hosting services provide businesses with a competitive edge, allowing them to allocate resources to other strategic initiatives.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="apply-now" id="approch">
                <div className='apply-image-container'>
                    <div className="container" >
                        <div className='col-lg-6 '>
                            <h2 style={{ paddingTop: '30px' }}>KumbhaaTech’s Approach to Affordable Web Hosting</h2>
                            <p style={{ color: 'white' }}>At KumbhaaTech, we understand the importance of cost-effective solutions that deliver results. Our approach to affordable web hosting focuses on:</p>
                        </div>
                        <div className="row" >

                            <HostingChoosing />

                        </div>
                    </div>
                </div>
            </section>

            <section className='key-module-bg' style={{ marginTop: "50px" }}>
                <div class="container">
                    <div className='features-courseProject'>
                        <div class="row">
                            <div class="col-lg-6">
                                <h2>Our Relateed Services</h2>
                            </div>
                            <div class="key-modules-container">

                                <div class="key-module">

                                    <h4>Content Writing</h4>
                                    <div class="module-content">
                                        <p>Unlock the full potential of your brand with our comprehensive digital marketing strategies, driving targeted traffic and maximizing conversion rates.</p>
                                        <button className="explore-button">
                                            <span className="explore-texts">Explore More</span>
                                            <span className="arrows">&rarr;</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="key-module">

                                    <h4>Web Design</h4>
                                    <div class="module-content">
                                        <p>We design your website with scalability in mind, allowing you to easily add new features and functionalities as your business grows.</p>
                                        <button className="explore-button">
                                            <span className="explore-texts">Explore More</span>
                                            <span className="arrows">&rarr;</span>
                                        </button>
                                    </div>
                                </div>

                                <div class="key-module">

                                    <h4>Web Hosting</h4>
                                    <div class="module-content">
                                        <p>Suspendisse tempor mauris a sem elementum bibendum. Praesent facilisis massa non vestibulum.</p>
                                        <button className="explore-button">
                                            <span className="explore-texts">Explore More</span>
                                            <span className="arrows">&rarr;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </section>

            <section className='fixed-bacground-section'>
                <div className="fixed-background-5">
                    <div className="fixed-background-content">
                        <div className="fixed-background-content-inside">
                            <h2>Ready to Secure Your Online Identity?</h2>
                            <p>Ensure your online presence starts with the right WebHosting. Contact Kumbhatech Solutions for WebHosting services. We provide a seamless process to help you secure your desired domain quickly and affordably. Let us assist you in establishing a strong online identity that aligns with your business goals!.</p>
                            <br />
                            <Link to='' className='benift-btn'>Connect With Us</Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="conclusion" id='Conclusion'>
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2 className='conclusion-heading'>conclusion</h2>
                        </div>
                        <div className="col-lg-6">
                            <div className="fist-conclusion-content">
                                <p>In conclusion, affordable web hosting plays a crucial role in enabling businesses and individuals to establish and maintain their online presence. From making websites accessible and ensuring reliable performance to providing security and scalability, web hosting services are essential for success in the digital age. Cheap web hosting services offer a cost-effective solution, ensuring accessibility, affordability, and quality without compromise. Contact KumbhaaTech today to learn more about our affordable web hosting services and take the first step towards a robust online presence.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">

                            <div className="second-conclusion-content">
                                <p>By choosing KumbhaaTech, you're partnering with a trusted web hosting provider that prioritizes affordability, quality, and reliability. Let us help you unlock the power of affordable web hosting and achieve your online goals with confidence.</p>
                                <Link className='conclusion-link'>Talk To Expert </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='news-letter'>
                <div className="news-letter-container">

                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6">
                                <div>
                                    <h4>Get Your Domain Today!</h4>
                                    <p>Ready to claim your online presence? Contact us now to register your domain with ease. Let Kumbhatech Solutions be your trusted partner in building a strong online foundation for your business!.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='subscribe-btnbox'>
                                    <button>Connect With Us <span>+</span></button></div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </Fragment>
    )
}

export default WebHosting;
/* <a href="https://www.freepik.com/free-photo/corporate-management-strategy-solution-branding-concept_28096443.htm#fromView=search&page=1&position=0&uuid=ff2715e4-6f8d-4035-9ed7-c0aeecfb5dd2">Image by rawpixel.com on Freepik</a>online */
/* <a href="https://www.freepik.com/free-photo/young-woman-working-laptop-isolated-white-background_8907406.htm#fromView=search&page=1&position=19&uuid=60ca384b-cb1a-41d2-af00-7881005c1521">Image by diana.grytsku on Freepik</a> profess */