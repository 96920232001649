import React from 'react';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer-widget">
              <h3>Quick Links</h3>
              <ul>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/">Carrer</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/about">How we Work?</Link></li>
               
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/about">Blogs</Link></li>
             
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer-widget">
              <h3>Quick Links</h3>
              <ul>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/">Home</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/about">About Us</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/services">Services</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/contact">Projects</Link></li>
              
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/contact">Contact Us</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer-widget">
              <h3>Quick Services</h3>
              <ul>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/">Web Development</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/services">Software Development</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/about">Content Writing</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/contact">Digital Marketing</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/contact">SOP Writing</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/contact">Domain Regitration</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/contact">Web Hosting</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer-widget">
              <h3>Contact Us</h3>
              <p>N0 216 15th Street, Periyar Nagar bus Stand S.R.P Bus Stand, SRP Colony, Chennai, Tamil Nadu.</p>
              <p>E-mail: support@kumbhatech.com</p>
              <p>Talk To Expert: +91-7880002227</p>
              <br/>
              <ul className="social-icons">
                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                <li><a href="#"><i className="fa fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
       <div className="footer-bottom">
        <p>Copyright ©2017 Kumbha Technologies,pvt,Ltd. All Rights Reserved. </p>
      </div> 
    </footer>
  );
};

export default Footer;
{/* <a href="https://www.flaticon.com/free-animated-icons/refresh" title="refresh animated icons">Refresh animated icons created by Freepik - Flaticon</a> */}
{/* <a href="https://www.flaticon.com/free-animated-icons/development" title="development animated icons">Development animated icons created by Freepik - Flaticon cost</a> */}
{/* <a href="https://www.flaticon.com/free-animated-icons/quality" title="quality animated icons">Quality animated icons created by Freepik - Flaticon quality</a>
<a href="https://www.flaticon.com/free-animated-icons/computer" title="computer animated icons">Computer animated icons created by Freepik - Flaticon voting</a>
<a href="https://www.flaticon.com/free-animated-icons/reunion" title="reunion animated icons">Reunion animated icons created by Freepik - Flaticon team</a> */}
{/* <a href="https://www.flaticon.com/free-animated-icons/anti-terrorism-day" title="anti terrorism day animated icons">Anti terrorism day animated icons created by Freepik - Flaticon space</a>
<a href="https://www.flaticon.com/free-animated-icons/full-screen" title="full screen animated icons">Full screen animated icons created by Freepik - Flaticon scal</a>
<a href="https://www.flaticon.com/free-animated-icons/customer-service" title="customer service animated icons">Customer service animated icons created by Freepik - Flaticon support</a> */}