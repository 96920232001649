import React, { Fragment, useState, useRef ,useEffect} from 'react'
import Header from '../Header';
import Footer from '../Footer';
import SubNavigation from '../OurServices/SubNav.jsx';
import Slider from 'react-slick';
import ContentChoosing from './ContentChoosing.jsx'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../slider.css';
import { Link } from 'react-router-dom';
const ContentWriting = () => {
    useEffect(() => {
        document.title = ' Expert Content Writing Services | KumbhaaTech Solutions';
        return () => {
            document.title = 'leading software company - KumbhaaTech Solutions';
        };
    }, []);
    const [activeTab, setActiveTab] = useState('Tab1');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const cards = [
        {
            image: "assets/images/visibility.webp",
            heading: "Increased Visibility and Reach",
            description: "High-quality content attracts and engages audiences, increasing visibility and reach for brands and businesses.",

        },
        {
            image: "assets/images/search-engine -ranking.webp",
            heading: "Improved Search Engine Rankings",
            description: "Search engines favor fresh, relevant, and high-quality content, leading to improved rankings and visibility in search engine results pages (SERPs).",

        },
        {
            image: "assets/images/branding.jpg",
            heading: "Enhanced Brand Authority and Credibility",
            description: "Consistently producing valuable and informative content helps establish brands as authorities in their field, earning the trust and respect of their audience.",

        },
        {
            image: "assets/images/boosting.webp",
            heading: "Boosted Engagement and Interactions",
            description: "Compelling and engaging content encourages interactions, such as likes, shares, comments, and clicks, driving engagement and building a loyal audience.",

        }
    ];

    const handlePrevious = () => {
        sliderRef.current.slickPrev();
    };

    const handleNext = () => {
        sliderRef.current.slickNext();
    };


    return (
        <Fragment>
            <Header />
            <div class="custom-banner">
                <div class="custom-banner-content">
                    <h1>Expert Content Writing Services at Affordable Prices</h1>
                    <p>Enhance your brand's voice with Kumbhaatech Solutions. Our team of experienced writers delivers high-quality, engaging content tailored to your business needs. Get top-notch content writing services without breaking the bank.</p>
                    <br />
                    <Link to='' className='abt-btn'>Boost Your Content Today</Link>
                </div>
            </div>
            <section className='subnav'>
                <div className="container" >
                    <div className='row'>
                        <div className="col-lg-12" >

                            <SubNavigation />
                        </div>
                    </div>
                </div>
            </section>
            <section className="web-development-container">
                <div className="container">
                    <div className="row">
                        <div className="web-heading col-lg-9">
                            <h2>Unveiling the Power of Content Writing: Uses, Benefits, and Importance in the Digital Age.</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="web-content">
                                <p>In today’s digital era, content is king. Whether it's web pages, blog posts, social media updates, or marketing materials, compelling and engaging content plays a pivotal role in attracting, informing, and engaging audiences. </p>
                                <p>At KumbhaaTech, we understand the significance of high-quality content in driving online success. That's why we provide affordable content writing services tailored to meet the diverse needs of businesses in the market.</p>
                                <button className="btn btn-primary">Learn More</button>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="image-container">
                                <img src="assets/images/sop-writing.webp" alt="Web Development" decoding='async' loading='lazy' />
                                {/* <a href="https://www.freepik.com/free-photo/man-is-using-laptop-books-notebook-top-view_136764075.htm#fromView=search&page=1&position=11&uuid=49bbeaeb-7d63-4fc3-86bb-d8be2c717f58">Image by pvproductions on Freepik</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="web-section" id='understanding'>
                <div class="container">

                    <div class="web-container align-items-center">
                        <div class="col-lg-5 web-img">
                            <img src="assets/images/content-writing.webp" alt="Understanding Content Writing" decoding='async' loading='lazy' />
                        </div>
                        <div class="col-lg-7 web-content">
                            <h2>Understanding Content Writing</h2>
                            <p>Content writing involves the creation of written material for various platforms and purposes, with the aim of informing, entertaining, or persuading audiences. From website content and blog posts to articles, case studies, and social media updates, content writing encompasses a wide range of formats and styles tailored to specific audiences and objectives.</p>

                            <button className='main-button'> Get Appoinment Now!</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="info-section" id="purpose">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">The Purpose of Content Writing</h2>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Informing and Educating</h3>
                                    <p>Content writing serves as a valuable resource for audiences seeking information, knowledge, or solutions to their problems.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Engaging and Entertaining</h3>
                                    <p>Compelling and engaging content captures the attention of audiences, keeping them entertained and interested in the message being conveyed.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Building Authority and Trust</h3>
                                    <p>High-quality content establishes the author or brand as an authority in their field, earning the trust and respect of their audience.</p>
                                </div>
                            </div>
                            <div className="info-card">
                                <div className="info-card-box">
                                    <h3>Driving Conversions and Sales</h3>
                                    <p>Persuasive and compelling content can influence purchasing decisions, driving conversions and sales for businesses.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="section-heading">Uses of Content Writing</h2>
                                    <p>Content writing finds applications across various industries and platforms, serving diverse needs and objectives. Some common uses include:</p>
                                </div>
                            </div>
                            <div className="info-card-with-icon-inner">
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/content-writing-icon.png" alt="Websites content" decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Website Content</h5>
                                        <p>Website content includes landing pages, product descriptions, about pages, and other information aimed at informing and engaging website visitors.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/content-writing-icon.png" alt="social media" decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Social Media Updates</h5>
                                        <p>Social media updates include posts, tweets, and status updates aimed at engaging followers and driving traffic to websites or other online platforms.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/content-writing-icon.png" alt="marketing material" decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Marketing Materials</h5>
                                        <p>Marketing materials such as brochures, flyers, and email newsletters use content to promote products, services, or events to target audiences.</p>
                                    </div>
                                </div>
                                <div className="info-card-item">
                                    <div className="card-with-icon-box">
                                        <div className="card-icon">
                                            <img src="assets/images/content-writing-icon.png" alt="blog post and article" decoding='async' loading='lazy' />
                                        </div>
                                        <h5>Blog Posts and Articles</h5>
                                        <p>Blog posts and articles provide valuable information, insights, and updates on topics relevant to the target audience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='benefit-slider-section' id='benefits'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className="benifit-heading">
                                <h2>Benefits of Content Writing</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="slider-container">
                                <Slider ref={sliderRef} {...settings}>
                                    {cards.map((card, index) => (
                                        <div className="slider-card" key={index}>
                                            <img src={card.image} alt={card.heading} className="card-image" decoding='async' loading='lazy' />
                                            <h4 className="card-heading">{card.heading}</h4>
                                            <p className="card-description">{card.description}</p>
                                            <Link className="explore-button">
                                                <span className="explore-texts">Explore More</span>
                                                <span className="arrows">&rarr;</span>
                                            </Link>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="bottom-container">
                                    <div className="custom-arrows">
                                        <button className="custom-arrow" onClick={handlePrevious}>
                                            &#8249;
                                        </button>
                                        <button className="custom-arrow" onClick={handleNext}>
                                            &#8250;
                                        </button>

                                    </div>
                                    <div className='service-links'>
                                        <Link to='/all-services'>All Our Services</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>At Kumbhatech Solutions, we understand this challenge. We are dedicated to providing affordable website design services that empower businesses of all sizes to establish a strong online presence without compromising on quality or functionality.</p>

                                    </div>
                                    <br />
                                    <Link to='' className='benift-btn'>explore More</Link>
                                </div>

                            </div>
                            <div className='benifit-info-card '>
                                <div className="benift-info-card-item">
                                    <div >
                                        <p>We believe that every business deserves the opportunity to thrive in the digital world, regardless of their financial constraints.</p>

                                    </div>
                                    <br />
                                    <Link to='' className='benift-btn'>Connect With Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='tab-section' id='importance'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='page-title'>
                                <h2>Importance of Affordable Content Writing</h2>
                                <p>In today’s competitive market, businesses of all sizes must prioritize cost-effective solutions without compromising quality. Affordable content writing services offer the following advantages.</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-container">
                        <div className='cloud-tab'>
                            <div className="tab-headers">
                                <button
                                    className={`tab-link ${activeTab === 'Tab1' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab1')}
                                >
                                    Cost-Effectiveness <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab2' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab2')}
                                >
                                    Scalability <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab3' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab3')}
                                >
                                    Consistency and Reliability <span>+</span>
                                </button>
                                <button
                                    className={`tab-link ${activeTab === 'Tab4' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Tab4')}
                                >
                                    Competitive Edge<span>+</span>
                                </button>
                            </div>
                            <div id="Tab1" className={`tab-content ${activeTab === 'Tab1' ? 'active' : ''}`}>
                                <p>Affordable content writing services make it accessible for businesses with limited budgets to invest in high-quality content that drives results.</p>
                            </div>
                            <div id="Tab2" className={`tab-content ${activeTab === 'Tab2' ? 'active' : ''}`}>
                                <p>Affordable content writing services can scale alongside businesses, accommodating increased content needs and demands without incurring significant additional costs.</p>
                            </div>
                            <div id="Tab3" className={`tab-content ${activeTab === 'Tab3' ? 'active' : ''}`}>
                                <p>Affordable content writing services provide businesses with access to consistent and reliable content production, ensuring a steady stream of high-quality content to engage and inform audiences.</p>
                            </div>
                            <div id="Tab4" className={`tab-content ${activeTab === 'Tab4' ? 'active' : ''}`}>
                                <p>By minimizing upfront investment and ongoing costs, affordable content writing services provide businesses with a competitive edge, allowing them to allocate resources to other strategic initiatives.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="apply-now" id="approch">
                <div className='apply-image-container'>
                    <div className="container" >
                        <div className='col-lg-6 '>
                            <h2 style={{ paddingTop: '30px' }}>KumbhaaTech’s Approach to Affordable Content Writing</h2>
                            <p style={{ color: 'white' }}>At KumbhaaTech, we understand the importance of cost-effective solutions that deliver results. Our approach to content writing focuses on:</p>
                        </div>
                        <div className="row" >

                            <ContentChoosing />

                        </div>
                    </div>
                </div>
            </section>

            <section className='key-module-bg' style={{ marginTop: "50px" }}>
                <div class="container">
                    <div className='features-courseProject'>
                        <div class="row">
                            <div class="col-lg-6">
                                <h2>Our Relateed Services</h2>
                            </div>
                            <div class="key-modules-container">

                                <div class="key-module">

                                    <h4>Domine registration</h4>
                                    <div class="module-content">
                                        <p>Secure your online presence with hassle-free domain registration services.</p>
                                        <button className="explore-button">
                                            <span className="explore-texts">Explore More</span>
                                            <span className="arrows">&rarr;</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="key-module">

                                    <h4>Web Design</h4>
                                    <div class="module-content">
                                        <p>We design your website with scalability in mind, allowing you to easily add new features and functionalities as your business grows.</p>
                                        <button className="explore-button">
                                            <span className="explore-texts">Explore More</span>
                                            <span className="arrows">&rarr;</span>
                                        </button>
                                    </div>
                                </div>

                                <div class="key-module">

                                    <h4>Web Hosting</h4>
                                    <div class="module-content">
                                        <p>Suspendisse tempor mauris a sem elementum bibendum. Praesent facilisis massa non vestibulum.</p>
                                        <button className="explore-button">
                                            <span className="explore-texts">Explore More</span>
                                            <span className="arrows">&rarr;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </section>
            <section className='fixed-bacground-section'>
                <div className="fixed-background-3">
                    <div className="fixed-background-content">
                        <div className="fixed-background-content-inside">
                            <h2>Ready to Enhance Your Content Strategy?</h2>
                            <p>Take your content to the next level with Kumbhatech Solutions' professional writing services. Contact us today for a free consultation and quote. We'll collaborate closely with you to understand your content needs, craft engaging and informative pieces, and recommend affordable solutions that resonate with your audience. Let our expert writers transform your content into a powerful tool for growth and engagement!.</p>
                            <br />
                            <Link to='' className='benift-btn'>Connect With Us</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="conclusion" id='Conclusion'>
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2 className='conclusion-heading'>conclusion</h2>
                        </div>
                        <div className="col-lg-6">
                            <div className="fist-conclusion-content">
                                <p>In conclusion, content writing plays a crucial role in driving online success for businesses in today’s digital age. From informing and educating audiences to engaging and entertaining them, high-quality content serves as a valuable asset for brands and businesses looking to stand out in a crowded marketplace. </p>
                            </div>
                        </div>
                        <div className="col-lg-6">

                            <div className="second-conclusion-content">
                                <p>Affordable content writing services offer businesses a cost-effective solution to their content needs, providing access to high-quality content that drives results without breaking the bank. Contact KumbhaaTech today to learn more about our affordable content writing services.</p>
                                <Link className='conclusion-link'>Talk To Expert </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='news-letter'>
                <div className="news-letter-container">

                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6">
                                <div>
                                    <h4>Get Started Today!</h4>
                                    <p>Ready to captivate your audience with impactful content? Contact us now for a consultation and quote. Let Kumbhatech Solutions help you achieve your content marketing goals effectively!.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='subscribe-btnbox'>
                                    <button>Connect With Us <span>+</span></button></div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </Fragment>
    )
}

export default ContentWriting;
/* <a href="https://www.freepik.com/free-photo/smiling-brunette-woman-shirt-pointing-with-paper-arrow-up_6516048.htm#fromView=search&page=1&position=41&uuid=48b0562f-693d-4353-9e0b-b0640469f367">Image by drobotdean on Freepik</a> boosed engaemnt */
/* <a href="https://www.freepik.com/free-photo/magnifying-glass-wooden-cubes-plaster-wooden-table_8999415.htm#fromView=search&page=1&position=18&uuid=9ac22484-a4e6-47c9-8f86-3d4242e0945d">Image by 8photo on Freepik</a>incrse visibility
 */